@font-face {
  font-family: 'Pretendard-Thin';
  src: url('./styles/fonts/Pretendard-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Light';
  src: url('./styles/fonts/Pretendard-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('./styles/fonts/Pretendard-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('./styles/fonts/Pretendard-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Pretendard';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  /* font-family: 'Pretendard', 'Roboto'; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
