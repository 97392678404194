.header2 {
  width: 100%;
  padding: 2rem 0;
  border-bottom: 1px solid #e3e3e3;
}
.header2 .logo {
  width: 18.2rem;
  cursor: pointer;
}
.logo_bk {
  display: block;
}
.header2 .down_btn {
  display: block;
}
.header2 .google_img2,
.header2 .app_img2 {
  display: none;
}
.google_img1 {
  max-height: 2.6rem;
  width: auto;
}
.app_img1 {
  max-height: 2.5rem;
  width: auto;
}
.header2 .down_btn {
  display: flex;
}
.down_btn .btn {
  width: 16rem;
  height: 6.4rem;
  border-radius: 4rem;
}
.down_btn .google_btn {
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
}

.sub_page {
  min-height: 80rem;
  padding: 4rem 0 6rem;
}
.terms_tit {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
}
.terms_cont {
  margin-top: 3rem;
  padding: 1rem 4rem;
}
.terms_cont_tit {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
}

@media (max-width: 767.98px) {
  .down_btn .google_img1,
  .down_btn .app_img1 {
    display: none;
  }
  .down_btn .google_img2,
  .down_btn .app_img2 {
    display: block;
  }
  .down_btn .btn {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .google_img2 {
    max-width: 2.5rem;
    margin-left: 0.5rem;
  }
  .app_img2 {
    max-width: 2.3rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .header2 {
    padding: 1rem 0;
  }
  .header2 .logo {
    width: 14rem;
  }
  .terms_cont {
    padding: 0 2rem;
  }

  .terms_tit {
    font-size: 2.8rem;
  }
}
