.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.paginationWrap {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  margin-top: 50px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.page-item.active .page-link {
  background-color: #5f97eb;
  font-size: 18px;
  color: white;
  border-radius: 4px;
}
@media (max-width: 1600px) {
}

@media (max-width: 1440px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  .page-link {
    padding: 6px 14px;
    font-size: 15px;
  }
  .page-item.active .page-link {
    font-size: 15px;
  }
}
@media (max-width: 420px) {
  .page-link {
    padding: 6px 12px;
    font-size: 13px;
  }
  .page-item.active .page-link {
    font-size: 13px;
  }
}
@media (max-width: 380px) {
  .page-link {
    padding: 6px 12px;
    font-size: 12px;
  }
  .page-item.active .page-link {
    font-size: 12px;
  }
}
