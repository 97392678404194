.selectWrap {
  border: 1px solid #e8e8e8;
  width: 150px;
  margin-bottom: 0;
  padding: 16px 16px;
  border-radius: 10px;
}
.selectText {
  font-size: 20px;
  margin-bottom: 0;
}

.selectItemWrap {
  display: flex;
  width: 150px;
  flex-direction: column;
  position: absolute;
  border: 1px solid #e8e8e8;
  z-index: 999;
  background-color: #fff;
  margin-top: 5px;
}

.selectItemText {
  padding: 16px 16px;
  font-size: 20px;
  background-color: #fff;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 0;
  /* justify-content: center; */
}
@media (max-width: 1600px) {
}

@media (max-width: 1440px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
  .selectWrap {
    padding: 10px 10px;
    width: 120px;
  }
  .selectItemWrap {
    width: 120px;
  }
  .selectText {
    font-size: 15px;
  }
  .selectItemText {
    font-size: 15px;
    padding: 10px 10px;
  }
}
@media (max-width: 420px) {
  .selectWrap {
    width: 100px;
    padding: 10px 10px;
  }
  .selectText {
    font-size: 13px;
  }
  .selectItemWrap {
    width: 100px;
  }
  .selectItemText {
    font-size: 13px;
  }
}
@media (max-width: 380px) {
  .selectWrap {
    width: 100px;
    padding: 10px 10px;
  }
  .selectText {
    font-size: 12px;
  }
  .selectItemWrap {
    width: 100px;
  }
  .selectItemText {
    font-size: 12px;
  }
}
