.QuoteTableWrap {
  display: flex;
  margin: 100px 300px;
  flex-direction: column;
}
.QuoteTopWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.searchWrap {
  display: flex;
  /* justify-content: flex-end; */
  flex-direction: row;
}
.sarchInputBox {
  width: 400px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-size: 17px;
  margin-right: 10px;
}
.SearchBtn {
  padding: 14px 24px;
  border-radius: 10px;
  background-color: #e8e8e8;
  border: 1px solid #e8e8e8;
  font-size: 17px;
}

.queteHeaderWrap {
  display: flex;
  flex-direction: row;
}
.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
  background-color: #f6f6f6;
  border-bottom: 1px solid #e8e8e8;
  padding: 20px 0px;
}
.tableHeaderText {
  font-size: 17px;
  margin-bottom: 0;
  /* text-align: center; */
}
.queteContentWrap {
  /* display: flex; */
  /* flex-direction: row; */
}
.quoteItemWrap {
  display: flex;
}

.quoteItem {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0px;
  border-bottom: 1px solid #e8e8e8;
}
.quoteItemText {
  font-size: 17px;
  margin-bottom: 0;
}
.quoteItemUpdownImg {
  width: 2.5rem;
  height: 2.5rem;
}
.BtnBox {
  display: flex;
  flex-direction: 'row';
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
}
.telBox {
  border-radius: 10px;
  color: #333333;
  border: 1px solid #333333;
  background-color: #fff;
  padding: 20px;
}
.kakaoBox {
  border-radius: 10px;
  color: #fff;
  border: 1px solid #333333;
  background-color: #333333;
  padding: 20px;
}
.btnText {
  margin-bottom: 0px;
  font-size: 17px;
}

@media (max-width: 1600px) {
  .QuoteTableWrap {
    margin: 100px 300px;
  }
}

@media (max-width: 1440px) {
  .QuoteTableWrap {
    margin: 70px 200px;
  }
}
@media (max-width: 1199.98px) {
  .QuoteTableWrap {
    margin: 50px 100px;
  }
}
@media (max-width: 991.98px) {
  .QuoteTableWrap {
    margin: 50px 90px;
  }
  .sarchInputBox {
    width: 300px;
  }
}
@media (max-width: 767.98px) {
  .QuoteTableWrap {
    margin: 50px 80px;
  }
  .sarchInputBox {
    width: 67%;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px;
  }
  .SearchBtn {
    font-size: 16px;
  }
  .tableHeaderText {
    font-size: 16px;
  }
  .quoteItemText {
    font-size: 16px;
  }
  .quoteItem {
    font-size: 16px;
  }
}
@media (max-width: 640px) {
  .QuoteTableWrap {
    margin: 50px 70px;
  }
  .sarchInputBox {
    width: 60%;
    font-size: 16px;
    margin-right: 10px;
  }
  .SearchBtn {
    font-size: 16px;
  }
  .tableHeaderText {
    font-size: 16px;
  }
  .quoteItemText {
    font-size: 16px;
  }
  .quoteItem {
    font-size: 16px;
  }
}
@media (max-width: 575.98px) {
  .QuoteTableWrap {
    margin: 20px 20px;
    justify-content: space-between;
  }
  .tableHeader {
    padding: 15px 0px;
  }
  .QuoteTopWrap {
    /* flex-wrap: wrap; */
    gap: 10px;
  }
  .searchWrap {
    /* display: flex; */
    /* justify-content: flex-end; */
    /* flex-direction: row; */
  }
  .sarchInputBox {
    /* width: 50%; */
    font-size: 15px;
    margin-right: 10px;
    padding: 10px;
  }
  .SearchBtn {
    font-size: 15px;
    padding: 10px 20px;
  }
  .tableHeaderText {
    font-size: 15px;
  }
  .quoteItemText {
    font-size: 15px;
  }
  .quoteItem {
    font-size: 15px;
    padding: 10px 0px;
  }
  .quoteItemUpdownImg {
    width: 2rem;
    height: 2rem;
  }
  .telBox {
    padding: 15px;
  }
  .kakaoBox {
    padding: 15px;
  }
  .btnText {
    font-size: 15px;
  }
}
@media (max-width: 420px) {
  .SearchBtn {
    font-size: 13px;
  }
  .tableHeaderText {
    font-size: 13px;
  }
  .quoteItemText {
    font-size: 13px;
  }
  .quoteItem {
    font-size: 13px;
    padding: 7px 0px;
  }

  .sarchInputBox {
    /* width: 50%; */
    font-size: 13px;
    margin-right: 10px;
    padding: 7px;
  }

  .btnText {
    font-size: 13px;
  }
}
@media (max-width: 380px) {
  .SearchBtn {
    font-size: 11px;
  }
  .tableHeaderText {
    font-size: 11px;
  }
  .quoteItemText {
    font-size: 11px;
  }
  .quoteItem {
    font-size: 11px;
    padding: 10px 0px;
  }
  .telBox {
    padding: 10px 15px;
  }
  .kakaoBox {
    padding: 10px 15px;
  }
  .btnText {
    font-size: 11px;
  }
}
