/* @font-face {
  font-family: 'Pretendard-Thin';
  src: url('./fonts/Pretendard-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Light';
  src: url('./fonts/Pretendard-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('./fonts/Pretendard-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('./fonts/Pretendard-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
} */
html {
  font-size: 10px;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Pretendard';
  font-size: 1.5rem;
  font-weight: 400;
  word-break: keep-all;
  line-height: 1.3;
}
li {
  list-style: none;
}
p {
  margin-bottom: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
.containerWrap {
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 1.6rem;
}

img {
  width: 100%;
}

/*푸터수정 240404 선영 시작*/

.footer_header .inner ul {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.footer_header .inner ul li a {
  font-size: 1.6rem;
  color: #3f3f3f !important;
}
.footer_btm {
  display: flex;
  justify-content: center;
}
.footer_body .inner p.company-info {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
}
.dividing-line {
  padding: 0 0.5rem;
}

/*푸터수정 240404 선영 종료*/

.main_wr {
  width: 100%;
  height: 90rem;
  background: url('../assets/img/main_img.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.header {
  width: 100%;
  padding: 4rem 0;
  position: fixed;
  top: 0;
  z-index: 100;
  transition: all ease-in-out 0.5s;
  overflow: hidden;
}
.logo_bk_img {
  display: none;
}
.header .down_btn {
  display: none;
}
.header .google_img2,
.header .app_img2 {
  display: none;
}
.google_img1 {
  max-height: 2.6rem;
  width: auto;
}
.app_img1 {
  max-height: 2.5rem;
  width: auto;
}

.quote_txt {
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}
.btn_main {
  border-width: 0;
}
.js-header.on > .containerWrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.js-header.on {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.3rem 0;
}
.js-header.on .logo_W {
  display: none;
}
.js-header.on .logo {
  width: 18.2rem;
  cursor: pointer;
}
.js-header.on .logo_bk_img {
  display: block;
}
.js-header.on .down_btn {
  display: flex;
}
.js-header.on .down_btn .btn_main {
  width: 16rem;
  height: 6.4rem;
  border-radius: 4rem;
}
.js-header.on .down_btn .google_btn {
  border: 1px solid #e3e3e3;
  padding: 1.8rem;
}
.js-header.on .down_btn .quote_btn {
  border: 1px solid #fff;
  padding: 1.8rem;
  background-color: transparent;
}

.main_wr .logo img {
  width: 21.3rem;
  height: auto;
}
.main_wr .main_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_tit {
  font-size: 6.7rem;
  color: #fff;
  font-weight: 800;
  line-height: 1.3;
  white-space: pre-line;
  text-align: center;
}
.main_tit2 {
  font-size: 3rem;
  color: #fff;
  text-align: center;
  margin-top: 3rem;
}
.main_sub_tit {
  font-size: 2.2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem;
  text-align: center;
}
.main_box .btn_box {
  margin-top: 6rem;
  display: flex;
  justify-content: center;
}
.main_box .btn_main {
  max-width: 23rem;
  width: 100%;
  height: 5.6rem;
  border-radius: 4rem;
  margin: 0 0.5rem;
}
.google_btn {
  background-color: #fff;
}
.apple_btn {
  background-color: #000;
  color: #fff;
}

.quote_btn {
  background-color: orange;
  color: #fff;
  border: 1px solid orange;
}

/* .cont01{background-color: #008256; display: flex; height: 100%; align-items: center;}
.cont01_txt{padding-left: 13%; width: 45%; font-size: 4.8rem; color: #fff; white-space: pre-line; font-weight: 700; line-height: 1.3;}
.cont01_txt span{color: #FFED72;}
.cont01 img{width: 55%;} */

.cont01 {
  background-color: #008256;
  position: relative;
  height: 90rem;
  overflow: hidden;
}
.cont01 .containerWrap {
  height: 100%;
  display: flex;
  align-items: center;
}
.cont01_txt {
  width: 45%;
}
.cont01_txt p {
  color: #fff;
  white-space: pre-line;
  line-height: 1.3;
}
.cont01_txt span {
  color: #ffed72;
}
.cont01_txt p.fs_30 {
  margin-top: 4.2rem;
}
.cont_img_wr {
  max-width: 60%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  height: 100%;
}
.cont01 img {
  width: auto;
  height: 100%;
}
.cont_img_m {
  display: none;
}

.cont02 {
  padding: 13rem 0;
  overflow: hidden;
}
.cont02_wr {
  display: flex;
  align-items: center;
  text-align: center;
}
.cont02_top img {
  max-width: 63rem;
}
.cont02_wr div {
  width: 50%;
}
.cont02_tit {
  font-size: 3.5rem;
}
.cont02_sub_tit {
  font-size: 2.6rem;
}
.cont02 .line {
  width: 7rem;
  height: 0.4rem;
  background-color: #000;
  margin: 4rem auto;
}
.cont02_bottom img {
  max-width: 44.8rem;
}

.cont03 {
  text-align: center;
  padding-bottom: 24rem;
  overflow: hidden;
}
.cont03 img {
  max-width: 113rem;
}
.cont03_tit {
  width: fit-content;
  margin: 0 auto 5rem;
  font-size: 3.5rem;
  padding: 1rem 6rem;
  border-radius: 4.7rem;
  background-color: #000;
  color: #fff;
}
.cont03 .m {
  display: none;
}

.cont04 {
  width: 100%;
  height: 90rem;
  background: url('../assets/img/cont04_img.jpg') no-repeat;
  background-size: cover;
  overflow: hidden;
}
.cont04 .containerWrap {
  height: 100%;
  display: flex;
  align-items: center;
}
.cont04 .row {
  width: 100%;
  margin-left: -4.5px;
  margin-right: -4.5px;
}
.cont04 .col {
  padding-left: 4.5px;
  padding-right: 4.5px;
}
.cont04 .col img {
  position: relative;
}
.cont04_txt {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 6rem;
}
.cont04_txt .p1 {
  font-size: 3.6rem;
  font-weight: 800;
  color: #fdde24;
  margin-bottom: 1.2rem;
}
.cont04_txt p.wh_pre {
  color: #fff;
  font-weight: 500;
  font-size: 1.6rem;
}

.cont04_cont {
  width: 25%;
  position: relative;
  padding: 5px;
}

.footer {
  text-align: center;
  padding: 4rem 0;
  background-color: #141414;
  overflow: hidden;
}
.footer img {
  max-width: 21.3rem;
}
.footer p {
  margin-top: 2rem;
  color: #3f3f3f;
  font-size: 1.8rem;
}

.wh_pre {
  white-space: pre-line;
}
.fs_30 {
  font-size: 3rem;
}
.fs_35 {
  font-size: 3.5rem;
}
.fs_48 {
  font-size: 4.8rem;
}

.fw_300 {
  font-weight: 300;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 800;
}

@media (max-width: 1600px) {
}

@media (max-width: 1440px) {
  .cont04_txt .p1 {
    font-size: 2.6rem;
  }
}

@media (max-width: 1199.98px) {
  .main_tit {
    font-size: 5.7rem;
  }
  .main_box .btn_box {
    margin-top: 5rem;
  }

  .cont01 {
    height: 90vh;
  }
  .cont01_txt p.fs_48 {
    font-size: 4rem;
  }
  .cont01_txt p.fs_30 {
    font-size: 2.6rem;
  }
  .cont02_tit {
    font-size: 3.2rem;
  }
  .cont02_sub_tit {
    font-size: 2.2rem;
  }

  .cont04_cont {
    width: calc(50% - 8%);
    padding: 0 5%;
    position: relative;
  }
  .cont04 {
    height: auto;
    padding: 10% 0;
  }
}

@media (max-width: 991.98px) {
  .main_wr .logo img {
    width: 18rem;
  }
  .main_tit {
    font-size: 5rem;
  }
  .main_tit2 {
    font-size: 2.8rem;
  }
  .main_sub_tit {
    font-size: 2rem;
  }
  .main_box .btn_main {
    max-width: 20rem;
  }

  .cont01 {
    height: 80vh;
  }
  .cont01_txt {
    width: 50%;
  }
  .cont01_txt p.fs_48 {
    font-size: 3.4rem;
  }
  .cont01_txt p.fs_30 {
    font-size: 2.2rem;
  }

  .cont03_tit {
    font-size: 2.8rem;
  }

  .cont04_cont {
    width: calc(50% - 2%);
    padding: 0 5%;
  }
}

@media (max-width: 767.98px) {
  .js-header.on .down_btn .google_img1,
  .js-header.on .down_btn .app_img1 {
    display: none;
  }
  .js-header.on .down_btn .google_img2,
  .js-header.on .down_btn .app_img2 {
    display: block;
  }
  .js-header.on .down_btn .btn_main {
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .google_img2 {
    max-width: 2.5rem;
    margin-left: 0.5rem;
  }
  .app_img2 {
    max-width: 2.3rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .main_wr {
    height: 80vh;
  }
  .header {
    padding: 2rem 0;
  }
  .main_wr .logo img {
    width: 15rem;
  }
  .main_tit {
    font-size: 3.6rem;
  }
  .main_tit2 {
    font-size: 2.4rem;
  }
  .main_sub_tit {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
  .main_box .btn_box {
    margin-top: 3.5rem;
  }
  .main_box .btn_main {
    height: 5.5rem;
  }

  .cont01 {
    height: auto;
    padding: 3rem 0 0;
  }
  .cont01 .containerWrap {
    flex-direction: column;
  }
  .cont01_txt {
    text-align: center;
    width: 100%;
  }
  .cont01_txt p.fs_48 {
    font-size: 3rem;
  }
  .cont01_txt p.fs_30 {
    font-size: 2rem;
    margin-top: 2rem;
  }
  .cont01 img {
    width: 100%;
    height: auto;
  }
  .cont_img_wr {
    display: none;
  }
  .cont_img_m {
    display: block;
  }

  .cont02 {
    padding: 6rem 0;
  }
  .cont02_wr div {
    width: 100%;
  }
  .cont02_top {
    flex-direction: column-reverse;
  }
  .cont02_top img {
    margin-top: 4rem;
    max-width: 50rem;
  }
  .cont02 .line {
    width: 5rem;
    height: 0.2rem;
    margin: 2rem auto;
  }
  .cont02_bottom {
    margin-top: 8rem;
    flex-direction: column;
  }
  .cont02_bottom img {
    margin-top: 4rem;
    max-width: 38rem;
  }
  .cont02_tit {
    font-size: 3rem;
  }
  .cont02_sub_tit {
    font-size: 2rem;
  }

  .cont03 {
    margin-top: 4rem;
    padding-bottom: 6rem;
  }
  .cont03_tit {
    margin: 0 auto 4rem;
  }
  .cont03 .pc {
    display: none;
  }
  .cont03 .m {
    display: block;
    padding: 0 15%;
  }

  .cont04_cont {
    width: calc(50% - 2%);
    padding: 0 5px;
  }

  .footer {
    padding: 3rem;
  }
  .footer img {
    max-width: 15rem;
  }
  .footer p {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 575.98px) {
  .main_wr .logo img {
    width: 13rem;
  }
  .main_box .btn_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }
  .main_box .btn_box .apple_btn {
    margin-top: 2rem;
  }
  .main_box .btn_box .quote_btn {
    margin-top: 2rem;
  }
  .main_tit {
    font-size: 3rem;
  }
  .main_tit2 {
    font-size: 1.6rem;
    white-space: pre-line;
  }
  .main_sub_tit {
    font-size: 1.6rem;
    margin-top: 0.5rem;
    color: #fff;
    white-space: pre-line;
  }

  .cont01_txt p.fs_48 {
    font-size: 2.6rem;
  }
  .cont01_txt p.fs_30 {
    font-size: 1.8rem;
  }

  .cont02_tit {
    font-size: 2.6rem;
  }
  .cont02_top img {
    max-width: 35rem;
  }
  .cont02_sub_tit {
    font-size: 1.6rem;
  }
  .cont02_bottom .cont_img_wr2 {
    padding: 0 14%;
  }
  .cont02_bottom img {
    max-width: 28rem;
  }

  .cont03_tit {
    font-size: 2.4rem;
    padding: 0.6rem 6rem;
  }
  .cont03 .m {
    display: block;
    padding: 0 5%;
  }

  .cont04_cont {
    width: calc(100% - 30%);
    padding: 15px 0;
  }

  .footer img {
    max-width: 13rem;
  }
}

@media (max-width: 420px) {
  .cont04_cont {
    width: calc(100% - 20%);
    padding: 10px 0;
  }

  .cont03 .m {
    display: block;
    padding: 0;
  }
}

@media (max-width: 380px) {
  .main_tit {
    font-size: 2.6rem;
  }

  .cont01_txt p.fs_48 {
    font-size: 2.4rem;
  }
  .cont02_tit {
    font-size: 2.4rem;
  }

  .cont03_tit {
    font-size: 2.2rem;
  }

  .cont04_cont {
    width: calc(100% - 10%);
    padding: 10px 0;
  }
}
